import { createApp } from "vue";
import App from "./App.vue";
import VueHtmlToPaper from './plugins/VueHtmlToPaper.js';
import vi from 'element-plus/es/locale/lang/vi'

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
// import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faFloppyDisk, faArrowLeft, faTrash, faXmark, faArrowRight, faPlus, faCircleInfo, faPenToSquare, faTriangleExclamation, faSpinner, faCircleNotch, faRightToBracket, faFileImport, faFileExport, faMinus, faPrint, faFileCirclePlus, faCheckSquare, faSquare, faWrench, faReceipt, faFileCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';

/* import specific icons */

import "@/core/plugins/prismjs";
import "bootstrap";

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

library.add(faFloppyDisk, faArrowLeft, faTrash, faXmark, faArrowRight, faPlus, faCircleInfo, faPenToSquare, faCircleQuestion, faTriangleExclamation, faSpinner, faCircleNotch, faRightToBracket, faFileImport, faFileExport, faMinus, faPrint, faFileCirclePlus, faCheckSquare, faSquare, faWrench, faReceipt, faFileCircleXmark);

app.component('font-awesome-icon', FontAwesomeIcon)

app.use(store);
app.use(router);
app.use(ElementPlus, { size: 'small', zIndex: 3000, locale: vi });
app.use(VueHtmlToPaper);

ApiService.init(app);
// initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");

export default app;
