function addStyles(win, styles) {
  styles.forEach((style) => {
    let link = win.document.createElement("link");
    link.setAttribute("rel", "stylesheet");
    link.setAttribute("type", "text/css");
    link.setAttribute("href", style);
    win.document.getElementsByTagName("head")[0].appendChild(link);
  });
}

const VueHtmlToPaper = {
  install(app, options = {}) {
    app.config.globalProperties.$htmlToPaper = (
      el,
      localOptions,
      cb = () => true
    ) => {
      let defaultSpecs = ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        defaultStyles = [];
      let {
        specs = defaultSpecs,
        styles = defaultStyles
      } = options;

      // If has localOptions
      // TODO: improve logic
      if (localOptions) {
        if (localOptions.specs) specs = localOptions.specs;
        if (localOptions.styles) styles = localOptions.styles;
      }

      specs = specs.length ? specs.join(",") : "";

      const element = window.document.getElementById(el);

      if (!element) {
        alert(`Element to print #${el} not found!`);
        return;
      }

      const ifprint = document.createElement("iframe");
        document.body.appendChild(ifprint);
        ifprint.setAttribute("style","height:0;width:0;");

      const win = ifprint.contentWindow;

      win.document.write(`
        <html>
          <head>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">
            <style>
              @page { size: auto;  margin: 1.2rem; }

              * {
                font-family: "Times New Roman", Times, serif;
              }

              body, p, table, div {
                font-size: 0.8rem;
              }

              table, tr, td, th {
                border: 1px solid black;
                border-collapse: collapse;
              }

              table td {
                border-bottom-style: dashed;
                border-top-style: dashed;
              }

              table tr {
                border-bottom-style: dashed;
                border-top-style: dashed;
              }

              .break-word {
                word-break: break-word !important;
              }
            </style>
          </head>
          <body>
            ${element.innerHTML}
          </body>
        </html>
      `);

      addStyles(win, styles);

      setTimeout(() => {
        win.document.close();
        win.focus();
        win.print();
        win.close();
        cb();
      }, 1000);

      return true;
    };
  }
};

export default VueHtmlToPaper;
