enum ReportType {
  SO_CAN_DOI_VAT_TU = 'so-can-doi-vat-tu',
  BANG_KE_CHI_TIET_NHAP = 'bang-ke-chi-tiet-nhap',
  BANG_KE_CHI_TIET_XUAT = 'bang-ke-chi-tiet-xuat',
  TONG_HOP_XUAT_VAT_TU = 'tong-hop-xuat-vat-tu',
  TONG_HOP_NHAP_VAT_TU = 'tong-hop-nhap-vat-tu',
  CHI_TIET_XUAT_THEO_DOI_TUONG = 'bang-ke-chi-tiet-xuat-theo-doi-tuong',
  TONG_HOP_VAT_TU_THEO_DOI_TUONG = 'tong-hop-vat-tu-theo-doi-tuong',
  THEO_DOI_THUC_HIEN_HOP_DONG = 'theo-doi-thuc-hien-hop-dong',
}

export default ReportType;
