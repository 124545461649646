import {
  IDinhMucCoDinhResponseBase,
  IVatTuHopDongResponseBase,
  IVatTuPhieuNhapKho,
  IVatTuResponseBaseWithApprovedStatus,
  IVatTuSearchInKhoResponseBase,
  IVatTuTrongKhoResponseBase,
  IVatTuWithSoLuongDaXuat,
  MessageItemResponse,
} from '@/core/interfaces/ApiResponses';
import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import Swal from 'sweetalert2/dist/sweetalert2.js';

const YEAR = "selected_year" as string;

const HOI_DONG_BBKN = 'hoi_dong_bbkn' as string;

export const getYearFromLocalStorage = (): string | null => {
  return window.localStorage.getItem(YEAR);
};

export const setSelectedYearToLocalStorage = (year: string) => {
  window.localStorage.setItem(YEAR, year);
};

export const removeSelectedYearFromLocalStorage = () => {
  window.localStorage.removeItem(YEAR);
};

export const setHoiDongBbkn = (data: any) => {
  window.localStorage.setItem(HOI_DONG_BBKN, data);
}

export const getHoiDongBbkn = (): {
  thuTu: number,
  hoTen: string,
  chucVu: string,
  thanhPhan: string,
}[] => {
  try {
    return window.localStorage.getItem(HOI_DONG_BBKN) !== null ? JSON.parse(window.localStorage.getItem(HOI_DONG_BBKN) as string) : [] as {
      thuTu: number,
      hoTen: string,
      chucVu: string,
      thanhPhan: string,
    }[];
  } catch {
    return [];
  }
}

export const apiServiceInstance = () => {
  ApiService.vueInstance.axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${JwtService.getToken()}`;
  ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
    "application/json";
  ApiService.vueInstance.axios.defaults.headers.common["year"] =
    getYearFromLocalStorage();
  return ApiService;
};

export const formatCurrencyAndQuantity = (
  value: number,
  isShowDecimal = true,
  currencyLocale = 'en-US'
) => {
  if (value === 0) {
    if (isShowDecimal) return '0.00';

    return '0';
  }

  let newVal = value;

  if (!isShowDecimal) newVal = Math.round(value);

  return Intl.NumberFormat(
    currencyLocale,
    { minimumFractionDigits: isShowDecimal ? 2 : 0 }
  ).format(newVal);
};

export const reverseFormatNumber = (val: string, locale = "en-IN") => {
  const thousandSeparator = Intl.NumberFormat(locale).format(11111).replace(/\p{Number}/gu, '');
  const decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/\p{Number}/gu, '');

  return parseFloat(val
    .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
    .replace(new RegExp('\\' + decimalSeparator), '.')
  );
};

export function isResponseError<T>(
  data: MessageItemResponse | T
): data is MessageItemResponse {
  return !!(data as MessageItemResponse).msg?.trim();
}

export const swalErrNotification = async (error, defaultErrMsg = 'Có lỗi xảy ra') => {
  if (!error) {
    await swalNotification(
      defaultErrMsg,
      'error',
    );

    return;
  }

  await swalNotification(
    getErrorMsg(error, defaultErrMsg),
    'error',
  );
}

export const swalNotification = async (
  textMessage: string,
  type: 'error' | 'success' = 'success',
  confirmButtonText = 'Đã hiểu',
) => {
  let buttonType;

  switch (type) {
    case 'error':
      buttonType = 'danger';
      break
    default:
      buttonType = 'primary';
      break;
  }
  await Swal.fire({
    text: textMessage,
    icon: type,
    buttonsStyling: false,
    confirmButtonText: confirmButtonText,
    customClass: {
      confirmButton: `btn btn-${buttonType}`,
    },
  })
}

export const getErrorMsg = (error, defaultError = 'Có lỗi xảy ra'): string => {
  return error.response?.data?.data?.msg || defaultError;
}

export const instanceOfIDinhMucCoDinhResponseBase = (obj: any): obj is IDinhMucCoDinhResponseBase => {
  try {
    return 'pivot' in obj;
  } catch (e) {
    console.log('not an instance of IDinhMucCoDinhResponseBase');

    return false;
  }
}

export const instanceOfIVatTuHopDongResponseBase = (obj: any): obj is IVatTuHopDongResponseBase => {
  try {
    return 'so_luong_trong_hop_dong' in obj;
  } catch {
    console.log('not an instance of IVatTuHopDongResponseBase');

    return false;
  }
}

export const instanceOfIVatTuPhieuNhapKhoResponseBase = (obj: any): obj is IVatTuPhieuNhapKho => {
  try {
    return 'pivot' in obj && 'id_phieu_nhap_kho' in obj.pivot;
  } catch {
    console.log('not an instance of IVatTuPhieuNhapKho');

    return false;
  }
}

export const instanceOfIVatTuTrongKhoResponseBase = (obj: any): obj is IVatTuTrongKhoResponseBase => {
  try {
    return 'pivot' in obj && 'id_kho' in obj.pivot;
  } catch {
    console.log('not an instance of IVatTuTrongKhoResponseBase');

    return false;
  }
}

export function strip(number) {
  return number * 1000 / 1000;
}

export const instanceOfIVatTuResponseBaseWithApprovedStatus = (obj: any): obj is IVatTuResponseBaseWithApprovedStatus => {
  try {
    return 'is_approved' in obj;
  } catch {
    console.log('not an instance of IVatTuResponseBaseWithApprovedStatus');
    return false;
  }
}

export const instanceOfIVatTuWithSoLuongDaXuat = (obj: any): obj is IVatTuWithSoLuongDaXuat => {
  try {
    return 'so_luong_da_xuat_kho' in obj;
  } catch {
    console.log('not an instance of IVatTuWithSoLuongDaXuat');

    return false;
  }
}

export const instanceOfIVatTuSearchInKhoResponseBase = (obj: any): obj is IVatTuSearchInKhoResponseBase => {
  try {
    return 'kho' in obj;
  } catch {
    console.log('not an instance of IVatTuSearchInKhoResponseBase');

    return false;
  }
}
