import { ApiResponse } from "./../interfaces/ApiResponses";
import ApiService from "@/core/services/ApiService";
import { AxiosResponse } from "axios";
import {
  AuthenticationDataResponse,
  IUserResponse,
  IUserResponseWithRoleBase,
} from "@/core/interfaces/ApiResponses";
import { apiServiceInstance } from "@/core/helpers/utils";

export class UserService {
  static login(
    phone: string,
    password: string
  ): Promise<AxiosResponse<AuthenticationDataResponse>> {
    return ApiService.vueInstance.axios.post("/api/v1/auth/login", {
      so_dien_thoai: phone,
      mat_khau: password,
    });
  }

  static getCurrentUserData(): Promise<AxiosResponse<{
    data: IUserResponse
  }>> {
    return apiServiceInstance().vueInstance.axios.post("/api/v1/auth/me");
  }

  static list(): Promise<{
    data: ApiResponse<{
      data: IUserResponseWithRoleBase[];
      current_page: number;
      first_page_url: string;
      from: number;
      last_page: number;
      last_page_url: string;
      next_page_url: string | null;
      per_page: number;
      prev_page_url: string | null;
      to: number;
      total: number;
    }>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      "/api/v1/nguoi-dung/danh-sach"
    );
  }

  static get(id): Promise<{
    data: ApiResponse<IUserResponseWithRoleBase>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/nguoi-dung/chi-tiet/${id}`,
    );
  }

  static refreshToken(): Promise<AxiosResponse<AuthenticationDataResponse>> {
    return apiServiceInstance().vueInstance.axios.post("/api/v1/auth/refresh");
  }

  static logout(): Promise<AxiosResponse> {
    return apiServiceInstance().vueInstance.axios.post("/api/v1/auth/logout");
  }
}
