import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { UserService } from '@/core/services/UserService';
import { IUserResponse } from '@/core/interfaces/ApiResponses';
import { UNAUTHORIZED } from '@/core/constants/error-messages';
import {
  getYearFromLocalStorage,
  removeSelectedYearFromLocalStorage,
  setSelectedYearToLocalStorage,
} from "@/core/helpers/utils";

export interface User {
  id: number;
  avatar: string;
  email?: string;
  emailVerifiedAt?: string;
  fullName: string;
  positionId: number;
  departmentId: number;
  password: string;
  encodePassword: string;
  phone: string;
  username: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  authenticationInformation: {
    access_token: string;
    token_type: string;
    expires_in: number;
  };
  isAuthenticated: boolean;
  selectedYear: string | null;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = null;
  user = {} as User;
  authenticationInformation = {} as {
    access_token: string;
    token_type: string;
    expires_in: number;
  };
  isAuthenticated = !!JwtService.getToken();
  selectedYear =
    getYearFromLocalStorage() !== null ? getYearFromLocalStorage() : null;

  get currentUser(): User {
    return this.user;
  }

  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  get getErrors() {
    return this.errors;
  }

  get getSelectedYear(): string | null {
    return this.selectedYear;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error.message;
  }

  @Mutation
  [Mutations.SET_AUTH](authenticationInformation: {
    access_token: string;
    token_type: string;
    expires_in: number;
  }) {
    this.isAuthenticated = true;
    this.authenticationInformation = { ...authenticationInformation };
    JwtService.saveToken(authenticationInformation.access_token);
  }

  @Mutation
  [Mutations.SET_USER](userResponse: IUserResponse) {
    this.user = {
      id: userResponse.id,
      avatar: userResponse.anh_dai_dien,
      email: userResponse.email,
      emailVerifiedAt: userResponse.email_verified_at,
      fullName: userResponse.ho_va_ten,
      positionId: userResponse.id_chuc_vu,
      departmentId: userResponse.id_don_vi,
      password: userResponse.mat_khau,
      encodePassword: userResponse.mat_khau_md5,
      phone: userResponse.so_dien_thoai,
      username: userResponse.ten_dang_nhap,
      createdAt: userResponse.created_at,
      updatedAt: userResponse.updated_at,
    };
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = null;
    JwtService.destroyToken();
    // removeSelectedYearFromLocalStorage();
    this.selectedYear = null;
  }

  @Mutation
  [Mutations.SET_YEAR](year: string) {
    setSelectedYearToLocalStorage(year);

    this.selectedYear = year;
  }

  @Mutation
  [Mutations.REMOVE_YEAR]() {
    // removeSelectedYearFromLocalStorage();
    this.selectedYear = null;
  }

  @Action({ rawError: true })
  async [Actions.LOGIN]({ phone, password }) {
    return UserService.login(phone, password)
      .then((res) => {
        this.context.commit(Mutations.SET_AUTH, res.data.data);
        this.context.commit(Mutations.SET_USER, res.data.data.user);
      })
      .catch((e) => {
        if (e.response?.data?.error === UNAUTHORIZED) {
          this.context.commit(Mutations.SET_ERROR, {
            message: "Sai mật khẩu hoặc số điện thoại.",
          });
        } else {
          this.context.commit(Mutations.SET_ERROR, {
            message: "Có lỗi xảy ra khi đăng nhập.",
          });
        }
      });
  }

  @Action
  [Actions.LOGOUT]() {
    return UserService.logout().finally(() => {
      this.context.commit(Mutations.PURGE_AUTH);
    });
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.VERIFY_AUTH]() {
    if (!JwtService.getToken()) {
      this.context.commit(Mutations.PURGE_AUTH);
    } else {
      return UserService.refreshToken()
        .then((authenticationResponse) => {
          this.context.commit(
            Mutations.SET_AUTH,
            authenticationResponse.data.data
          );
          this.context.commit(
            Mutations.SET_USER,
            authenticationResponse.data.data.user
          );
        })
        .catch(() => {
          this.context.commit(Mutations.SET_ERROR, {
            message: "Có lỗi xảy ra, cần đăng nhập lại",
          });
          this.context.commit(Mutations.PURGE_AUTH);
        });
    }
  }

  @Action({ rawError: true })
  [Actions.REFRESH_TOKEN]() {
    if (!JwtService.getToken()) {
      this.context.commit(Mutations.PURGE_AUTH);
    } else {
      return UserService.refreshToken()
        .then((authenticationResponse) => {
          this.context.commit(
            Mutations.SET_AUTH,
            authenticationResponse.data.data
          );
          this.context.commit(
            Mutations.SET_USER,
            authenticationResponse.data.data.user
          );
        })
        .catch(() => {
          this.context.commit(Mutations.SET_ERROR, {
            message: "Có lỗi xảy ra, cần đăng nhập lại",
          });
          this.context.commit(Mutations.PURGE_AUTH);
        });
    }
  }
}
